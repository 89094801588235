import React, { FunctionComponent, ReactElement } from 'react';
import { SEO, PharmacyJumbotron, PharmacySummary1, CallToAction } from '../components';
import { MainLayout } from '../layouts';

const PharmacyPage: FunctionComponent = (): ReactElement => {
    return (
        <MainLayout>
            <SEO title="PCH | Pharmacy"/>
            <PharmacyJumbotron/>
            <PharmacySummary1/>
            <CallToAction/>
        </MainLayout>
    );
}

export default PharmacyPage;
